'use client';

import Script from 'next/script';

export const ChatWidget = () => {
  return (
    <>
      {/* ChatWidget */}
      <Script
        type="text/javascript"
        src="https://api.leadconnectorhq.com/js/reviews_widget.js"
      ></Script>
      <Script
        src="https://widgets.leadconnectorhq.com/loader.js"
        data-resources-url="https://widgets.leadconnectorhq.com/chat-widget/loader.js"
      ></Script>
      {/* END ChatWidget */}
    </>
  );
};
