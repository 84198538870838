'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { WEBSITE_ENV } from './helpers';
import Script from 'next/script';

export const GtmPageView = () => {
  const pathname = usePathname();

  useEffect(() => {
    window.dataLayer?.push({
      event: 'page_view',
      page: window.location.href,
    });
  }, [pathname]);

  return null;
};

export const GtmPackage = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-NV2BCB2' });
  }, []);

  return null;
};

export const GtmScript = () => {
  return (
    <>
      {/* Google Tag Manager */}
      {WEBSITE_ENV === 'production' && (
        <Script id="google-tag-manager">
          {`
             (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
             new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
             j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
             'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
             })(window,document,'script','dataLayer','GTM-NV2BCB2');
            `}
        </Script>
      )}
      {/* END Google Tag Manager */}
    </>
  );
};

export const GtmNoScriptIframe = () => {
  return (
    <>
      {/* Google Tag Manager */}
      {WEBSITE_ENV === 'production' && (
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-NV2BCB2"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          ></iframe>
        </noscript>
      )}
      {/* END Google Tag Manager */}
    </>
  );
};
