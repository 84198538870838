'use client';

import { useEffect, useState } from 'react';
// import { useLocation } from 'react-router';
// import Payment from 'payment';

export const isDesktop = () => {
  const query = `(min-width: 800px)`;
  return window.matchMedia(query).matches;
};

export const isMobile = () => {
  const query = `(max-width: 800px)`;
  return window.matchMedia(query).matches;
};

export const isDesktopBig = () => {
  const query = `(min-width: 3500px)`;
  return window.matchMedia(query).matches;
};

// export const ScrollToTop = ({ children }) => {
//   const location = useLocation();
//   useLayoutEffect(() => {
//     setTimeout(() => {
//       window.scrollTo(0, 0);
//     }, 200);
//   }, [location.pathname]);
//   return children;
// };

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const t = 'true';

export const useWindowWide = (size) => {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setWidth]);

  return width > size;
};

export const Env = process.env;
export const facebookLink = Env.NEXT_PUBLIC_FACEBOOK_LINK;
export const instagramLink = Env.NEXT_PUBLIC_INSTAGRAM_LINK;
export const linkedInLink = Env.NEXT_PUBLIC_LINKED_IN_LINK;

export const WIDGET_FORM_URL = 'https://api.leadconnectorhq.com/widget/form/';
export const WIDGET_BOOKING_URL =
  'https://api.leadconnectorhq.com/widget/booking/';
export const ROMAN_ID = '50AvXgnzHA4vHISzjvg7';
export const YURIY_ID = 'E8LmPXN1Smn9tGuQx1Tr';

export const PRICING_PLAN_UNIVERSAL_ID = 'kERd5xlNow6xzfuH314r';
export const PRICING_PLAN_BOOSTED_ID = 'o0rmSACICouZKHveLHBi';
export const PRICING_PLAN_SUPERCHARGED_ID = 'biw7ZUnb5PjdyNndBlsS';

export const UNIVERSAL = 'kERd5xlNow6xzfuH314r';
export const BOOSTED = 'o0rmSACICouZKHveLHBi';
export const SUPERCHARGED = 'biw7ZUnb5PjdyNndBlsS';

export const SITE_URL = 'https://vgrubs.com';

export const I_FRAME_FORM_STYLE = {
  border: 'none',
  width: '100%',
  height: 900,
};

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  unitDisplay: 'short',
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const formatterDollarShort = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  notation: 'compact',
  compactDisplay: 'short',
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const displayCentsInDollars = (cents) =>
  formatter.format(cents ? cents / 100 : 0);

export function removeObjectWithId(arr, id) {
  return arr.filter((obj) => obj.id !== id);
}

function preloadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      reject(src);
    };
    img.src = src;
  });
}

export function useImagePreloader(imageList) {
  const [imagesPreloaded, setImagesPreloaded] = useState(false);

  useEffect(() => {
    let isCancelled = false;

    async function effect() {
      console.log('PRELOAD');

      if (isCancelled) {
        return;
      }

      const imagesPromiseList = [];
      for (const i of imageList) {
        imagesPromiseList.push(preloadImage(i));
      }

      await Promise.all(imagesPromiseList);

      if (isCancelled) {
        return;
      }

      setImagesPreloaded(true);
    }

    effect();

    return () => {
      isCancelled = true;
    };
  }, [imageList]);

  return { imagesPreloaded };
}

export const formatDate = (iso, timezone) => {
  var date_str = iso,
    options = {
      timezone,
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
    formatted = new Date(date_str).toLocaleDateString('en-US', options);

  return formatted;
};

export const formatDateUTC = (iso) => {
  var date_str = iso,
    options = {
      timeZone: 'UTC',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    formatted = new Date(date_str).toLocaleDateString('en-US', options);

  return formatted;
};

export const isAdmin = process.env.NEXT_PUBLIC_ADMIN === 'true';
export const isPartner = process.env.NEXT_PUBLIC_PARTNER === 'true';
export const isSales = process.env.NEXT_PUBLIC_SALES === 'true';

export const HEADERS = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'app-version': 'web-version-1.0.0',
    'content-type': 'application/json',
  },
};

export const API_URL_PARTNER = process.env.NEXT_PUBLIC_API_URL;
export const API_URL_ADMIN = process.env.NEXT_PUBLIC_API_URL_ADMIN;
export const API_URL_SALES = process.env.NEXT_PUBLIC_API_URL_SALES;
export const INITIAL_PAGINATION_PAGE = 1;
export const PAGINATION_LIMIT = 20;
export const handleShowPaginationButton = (arrayLength, page) =>
  arrayLength === PAGINATION_LIMIT * page;

export const handleSetUrlSearchParams = (key, value, setSearchParams) => {
  setSearchParams((prevParams) => {
    if (value === null || value === undefined || value === '') {
      prevParams.delete(key);
    } else {
      prevParams.set(key, value);
    }
    return prevParams;
  });
};

export const renderMonthName = (month) => {
  switch (month) {
    case 1:
      return 'Jan';
    case 2:
      return 'Feb';
    case 3:
      return 'Mar';
    case 4:
      return 'Apr';
    case 5:
      return 'May';
    case 6:
      return 'Jun';
    case 7:
      return 'Jul';
    case 8:
      return 'Aug';
    case 9:
      return 'Sep';
    case 10:
      return 'Oct';
    case 11:
      return 'Nov';
    case 12:
      return 'Dec';

    default:
      break;
  }
};

export const setDocumentTitle = (pageTitle) =>
  (document.title = `${pageTitle}`);

function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}

// export function formatCreditCardNumber(value) {
//   if (!value) {
//     return value;
//   }

//   const issuer = Payment.fns.cardType(value);
//   const clearValue = clearNumber(value);
//   let nextValue;

//   switch (issuer) {
//     case 'amex':
//       nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
//         4,
//         10
//       )} ${clearValue.slice(10, 15)}`;
//       break;
//     case 'dinersclub':
//       nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
//         4,
//         10
//       )} ${clearValue.slice(10, 14)}`;
//       break;
//     default:
//       nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
//         4,
//         8
//       )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
//       break;
//   }

//   return nextValue.trim();
// }

export const yearToday = new Date().getFullYear();

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 3;

  // if (allValues.number) {
  //   const issuer = Payment.fns.cardType(allValues.number);
  // }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const WEBSITE_ENV = process.env.NEXT_PUBLIC_WEBSITE_ENV;

export const psw = (path, pathname) => pathname.startsWith(path);

export const Environment = {
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
};
