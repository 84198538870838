import axios from 'axios';

export const apiCustomer = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'app-version': 'web-version-1.0.0',
    'content-type': 'application/json',
  },
});
