export const ROOT = '/';
export const REQUEST_DEMO = '/schedule-a-call';
export const PRICING = '/pricing';
export const PAYMENT = '/payment';
export const PAYMENT_FSG = '/payment-fsg';
export const PRICING_UNIVERSAL = '/pricing/universal';
export const PRICING_BOOSTED = '/pricing/boosted';
export const PRICING_SUPERCHARGED = '/pricing/supercharged';

export const BRANDS = '/brands';
export const FAQ = '/faq';
export const CONTACT = '/contact';
export const DEVICE_SETUP_GUIDE = '/device-setup-guide';
export const PRIVACY_POLICY = '/privacy';
export const TERMS_AND_CONDITIONS = '/terms';
export const SCHEDULE_A_CALL = '/schedule-a-call';
export const CONCIERGE = '/concierge';
export const WHY_US = '/why-vgrubs';
export const BLOG = '/blogs';
export const V_DRIVE = '/vdrive';

export const NOT_FOUND = '/*';

export const THANK_YOU = '/thank-you';

export const PRICING_UNIVERSAL_THANK_YOU = `${PRICING_UNIVERSAL}${THANK_YOU}`;
export const PRICING_BOOSTED_THANK_YOU = `${PRICING_BOOSTED}${THANK_YOU}`;
export const PRICING_SUPERCHARGED_THANK_YOU = `${PRICING_SUPERCHARGED}${THANK_YOU}`;

export const FSG = '/fsg';
export const FSG_2 = '/fsg-2';
export const FSG_ORDER_BOOST = `${FSG}/order-boost`;
export const FSG_REVENUE_RECOVERY = `${FSG}/revenue-recovery`;
export const FSG_SUPERCHARGED = `${FSG}/supercharged`;

export const FSG_ORDER_BOOST_THANK_YOU = `${FSG}/order-boost/thank-you`;
export const FSG_REVENUE_RECOVERY_THANK_YOU = `${FSG}/revenue-recovery/thank-you`;
export const FSG_SUPERCHARGED_THANK_YOU = `${FSG}/supercharged/thank-you`;

export const VIRTUAL_RESTAURANTS = '/virtual-restaurants';

export const FRANCHISE_NOTICE = '/franchise-notice';

export const AFF = '/aff';

export const PDF1 = '/Wi-Fi_Printer_Setup.pdf';
export const PDF2 = '/Tablet_Setup.pdf';
export const PDF3 = '/LAN_Printer_Setup.pdf';
export const PDF4 = '/CS_Island_Bagel_Bar.pdf';
export const PDF5 = '/CS_Island_Bagel_Bar_FSG.pdf';
export const PDF6 = '/CS_Bagel_Boss.pdf';
export const PDF7 = '/CS_Bagel_Boss_FSG.pdf';
export const PDF8 = '/Bluetooth_Printer_Setup.pdf';

export const mainRoutes = [
  {
    path: ROOT,
    name: 'Home',
  },
  {
    path: REQUEST_DEMO,
    name: 'Request Demo',
  },
  {
    path: PRICING,
    name: 'Pricing',
  },
  {
    path: PAYMENT,
    name: 'Payment',
  },
  {
    path: BRANDS,
    name: 'Our Brands',
  },
  {
    path: CONCIERGE,
    name: 'Concierge',
  },
  {
    path: V_DRIVE,
    name: 'vDrive',
  },
  {
    path: WHY_US,
    name: 'Why vGrubs?',
  },
  {
    path: VIRTUAL_RESTAURANTS,
    name: 'Virtual Restaurants',
  },
  {
    path: BLOG,
    name: 'Blogs',
  },
  {
    path: FAQ,
    name: 'FAQ',
  },
  {
    path: CONTACT,
    name: 'Contact',
  },
  {
    path: DEVICE_SETUP_GUIDE,
    name: 'Device Setup',
  },
  {
    path: PRIVACY_POLICY,
    name: 'Privacy Policy',
  },
  {
    path: TERMS_AND_CONDITIONS,
    name: 'Terms & Conditions',
  },
];

export const allRoutes = [
  {
    path: '',
  },

  {
    path: PRICING,
  },
  {
    path: PAYMENT,
  },
  {
    path: PAYMENT_FSG,
  },
  {
    path: BRANDS,
  },
  {
    path: CONCIERGE,
  },
  {
    path: V_DRIVE,
  },
  {
    path: FAQ,
  },
  {
    path: CONTACT,
  },
  {
    path: DEVICE_SETUP_GUIDE,
  },
  {
    path: PRIVACY_POLICY,
  },
  {
    path: TERMS_AND_CONDITIONS,
  },
  {
    path: SCHEDULE_A_CALL,
  },
  {
    path: WHY_US,
  },
  {
    path: BLOG,
  },
  {
    path: THANK_YOU,
  },
  {
    path: FSG,
  },
  {
    path: FSG_ORDER_BOOST,
  },
  {
    path: FSG_REVENUE_RECOVERY,
  },
  {
    path: FSG_SUPERCHARGED,
  },
  {
    path: VIRTUAL_RESTAURANTS,
  },
  {
    path: FRANCHISE_NOTICE,
  },
  {
    path: PDF1,
  },
  {
    path: PDF2,
  },
  {
    path: PDF3,
  },
  {
    path: PDF4,
  },
  {
    path: PDF5,
  },
  {
    path: PDF6,
  },
  {
    path: PDF7,
  },
  {
    path: PDF8,
  },
];
